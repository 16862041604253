import React from "react";

export default function Home() {
    return (
        <div className="home-page">
            <div className="grid-master">
                <div className="grid-first-row">
                    <div className="grid-first-row-container1" />
                    <div className="grid-first-row-container2" />
                    <div className="grid-first-row-container3" />
                </div>
                <div className="grid-second-row">
                    <div className="grid-second-row-container1" />
                    <div className="grid-second-row-container2" />
                    <div className="grid-second-row-container3" />
                </div>
                <div className="grid-first-row">
                    <div className="grid-first-row-container1" />
                    <div className="grid-first-row-container2" />
                    <div className="grid-first-row-container3" />
                </div>
                <div className="grid-second-row">
                    <div className="grid-second-row-container1" />
                    <div className="grid-second-row-container2" />
                    <div className="grid-second-row-container3" />
                </div>
                <div className="grid-first-row">
                    <div className="grid-first-row-container1" />
                    <div className="grid-first-row-container2" />
                    <div className="grid-first-row-container3" />
                </div>
                <div className="grid-second-row">
                    <div className="grid-second-row-container1" />
                    <div className="grid-second-row-container2" />
                    <div className="grid-second-row-container3" />
                </div>
                <div className="grid-first-row">
                    <div className="grid-first-row-container1" />
                    <div className="grid-first-row-container2" />
                    <div className="grid-first-row-container3" />
                </div>
                <div className="grid-second-row">
                    <div className="grid-second-row-container1" />
                    <div className="grid-second-row-container2" />
                    <div className="grid-second-row-container3" />
                </div>
            </div>
        </div>
    );
}
