import React, { Component } from "react";
import { Link } from "react-router-dom";

class Contact extends Component {
    render() {
        return (
            <div className="page">
                <br />
                <br />
                <br />
                <br />
                <h1>CONTACT</h1>
                <div className="page-text">
                    <p>IONUTMITRA@GMAIL.COM</p>
                    <p>+40 7000 000 000</p>
                </div>
                <input placeholder="FIRST NAME" />
                <br />
                <input className="main-input" placeholder="LAST NAME" />
                <br />
                <input className="main-input" placeholder="EMAIL ADDRESS" />
                <br />
                <input className="main-input" placeholder="PHONE NUMBER" />
                <br />
                <textarea className="main-textarea" placeholder="MESSAGE" />
                <br />
                <br />
                <button className="main-button">SUBMIT</button>
                <br />
                <br />
                <Link to="/">
                    <button className="back-button">BACK TO HOME</button>
                </Link>
                <br />
                <br />
                <br />
            </div>
        );
    }
}

export default Contact;
