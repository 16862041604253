import React from "react";
import { Link } from "react-router-dom";
import DrawerButton from "../Drawer/DrawerButton";

function Toolbar(props) {
    return (
        <div className="toolbar">
            <div className="toolbar-content">
                <div className='drawer-btn-min'>
                    <DrawerButton click={props.drawerClickHandler} />
                </div>
                <div className="toolbar-links">
                    <Link to="/">
                        <h1>HOME</h1>
                    </Link>
                    <Link to="/classic-art">
                        <h1>EXPLORE</h1>
                    </Link>
                    <Link to="/apparel">
                        <h1>COLLECTIONS</h1>
                    </Link>
                    <Link to="/contact">
                        <h1>CONTACT</h1>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default Toolbar;
