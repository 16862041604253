import React from "react";
import { Link } from "react-router-dom";

const Drawer = (props) => {
    let drawerClasses = "drawer";
    if (props.show) {
        drawerClasses = "drawer open";
    }

    return (
        <div onClick={props.backdropClickHandler}>
            <nav className={drawerClasses}>
                <div className="drawer-links" >


                    <Link to="/">
                        <h1>HOME</h1>
                    </Link>

                    <Link to="/classic-art">
                        <h1>EXPLORE</h1>
                    </Link>

                    <Link to="/apparel">
                        <h1>COLLECTIONS</h1>
                    </Link>
                    <Link to="/contact">
                        <h1>CONTACT</h1>
                    </Link>
                    <Link to="/about">
                        <h1>ABOUT</h1>
                    </Link>



                </div>
            </nav>
        </div>
    );
};

export default Drawer;
