import React from "react";

const DrawerButton = (props) => (
    <button className="drawer-button" onClick={props.click} id="hamburger">



        <div className="drawer-button-line1"></div>
        <div className="drawer-button-line2"></div>
        <div className="drawer-button-line3"></div>
        <div className="drawer-button-line4"></div>














    </button>
);

export default DrawerButton;
