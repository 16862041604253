import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="footer-content">
                    <Link to="/admin">
                        <p>ADMIN</p>
                    </Link>
                    <Link to="/terms-and-conditions">
                        <p>LEGAL</p>
                    </Link>

                    <Link to="/contact">
                        <p>CONTACT</p>
                    </Link>

                    <div className="footer-socialmedia">
                        <a href="https://www.facebook.com/ionutmitra">
                            <FaFacebookSquare size={29} />
                        </a>
                        <a href="https://www.instagram.com/ionut_mitra">
                            <FaInstagram className="footer-icon" size={30} />
                        </a>
                    </div>

                    <p className="footer-credit">2020 &copy; HUSNUDINISLAM.COM</p>
                </div>
            </div>
        );
    }
}

export default Footer;
