import React from 'react'
import { Link } from "react-router-dom"

export default function Store() {
    return (
        <div className="page">
            <br />
            <br />
            <br />
            <br />
            <h1>COLLECTIONS</h1>
            <div className="page-button-section">
                <div className="page-button-section2">
                    <Link to="/apparel">
                        <h1>APPAREL</h1>
                    </Link>
                </div>

                <div className="page-button-section1">
                    <h1>ARTWORKS</h1>
                </div>
            </div>

            <Link to="/">
                <button className="back-button">BACK TO HOME</button>
            </Link>

        </div>
    )
}
