import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import Toolbar from "./Components/Toolbar/Toolbar";
import Drawer from "./Components/Drawer/Drawer";

import Footer from "./Components/Footer";
import Backdrop from "./Components/Backdrop";
import ScrollToTopRoute from "./Components/ScrollToTopRoute";

import Home from "./Pages/Home";
import UrbanArt from "./Pages/Explore/UrbanArt";
import ClassicArt from "./Pages/Explore/ClassicArt";

import Apparel from "./Pages/Collections/Apparel";
import Artworks from "./Pages/Collections/Artworks";

import Login from "./Pages/Admin/Login";
import Admin from "./Pages/Admin/Dashboard";

import Contact from "./Pages/Default/Contact";
import About from "./Pages/Default/About";
import Terms from "./Pages/Legal/Terms";
import Privacy from "./Pages/Legal/Privacy";
import Cookies from "./Pages/Legal/Cookies";

class Main extends Component {
    state = {
        drawerOpen: false,
    };
    drawerClickHandler = () => {
        this.setState((prevState) => {
            return { drawerOpen: !prevState.drawerOpen };
        });
    };
    backdropClickHandler = () => {
        this.setState({ drawerOpen: false });
    };
    render() {
        let backdrop;

        if (this.state.drawerOpen) {
            backdrop = <Backdrop click={this.backdropClickHandler} />;
        }
        return (
            <BrowserRouter>
                <Drawer
                    show={this.state.drawerOpen}
                    backdropClickHandler={this.backdropClickHandler}
                />
                <Toolbar drawerClickHandler={this.drawerClickHandler} />

                {backdrop}

                <Switch>
                    <ScrollToTopRoute exact path="/" component={Home} />;
                    <ScrollToTopRoute path="/about" component={About} />;
                    <ScrollToTopRoute path="/contact" component={Contact} />;

                    <ScrollToTopRoute path="/login" component={Login} />;
                    <ScrollToTopRoute path="/admin" component={Admin} />;

                    <ScrollToTopRoute path="/classic-art" component={ClassicArt} />;
                    <ScrollToTopRoute path="/urban-art" component={UrbanArt} />;

                    <ScrollToTopRoute path="/apparel" component={Apparel} />;
                    <ScrollToTopRoute path="/artworks" component={Artworks} />;

                    <ScrollToTopRoute path="/terms-and-conditions" component={Terms} />;
                    <ScrollToTopRoute path="/privacy-policy" component={Privacy} />;
                    <ScrollToTopRoute path="/cookie-policy" component={Cookies} />;


        </Switch>
                <Footer />
            </BrowserRouter>
        );
    }
}

export default Main;
