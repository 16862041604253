import React, { Component } from "react";
import { Link } from "react-router-dom";

export class ArtAndDeco extends Component {
    render() {
        return (
            <div className="page">
                <br />
                <br />
                <br />
                <br />
                <h1>EXPLORE</h1>

                <div className="page-button-section">
                    <div className="page-button-section1">
                        <h1>CLASSIC</h1>
                    </div>

                    <div className="page-button-section2">
                        <Link to="/urban-art">
                            <h1>URBAN</h1>
                        </Link>
                    </div>
                </div>
                <h3>PAINTINGS</h3>
                <div className="explore-thumbnail" />
                <h3>SCULPTURES</h3>
                <Link to="/">
                    <button className="back-button">BACK TO HOMEPAGE</button>
                </Link>
                <br />
                <br />
                <br />
            </div>

        );
    }
}

export default ArtAndDeco;
