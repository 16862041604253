import React, { Component } from "react";
import { Link } from "react-router-dom";

class Terms extends Component {
    render() {
        return (
            <div className="page">
                <br />
                <br />
                <br />
                <br />
                <h1>LEGAL</h1>
                <div className="page-button-section">
                    <div className="page-button-section2">
                        <Link to="/terms-and-conditions">
                            <h1>TERMS</h1>
                        </Link>
                    </div>

                    <div className="page-button-section2">
                        <Link to="/privacy-policy">
                            <h1>PRIVACY</h1>
                        </Link>
                    </div>

                    <div className="page-button-section1">
                        <h1>COOKIES</h1>
                    </div>
                </div>
                <p className="page-text">
                    COOKIE POLICY
                     <br />
                    ------------- ------------- --------- ------------- ---------
                    ------------- ------------ ------------ --------- -------------
                    --------- ------------- --------- ------------- -------------
                    ------------- ------------- ------------- ------------- -------------
                    ------------- ------------- ------------- ------------- -------------
                    ------------- ------------- ------------- ------------- -------------
                    ------------- -------------
                </p>
                <br />
                <br />
                <Link to="/">
                    <button className="back-button">BACK TO HOME</button>
                </Link>
                <br />
                <br />
                <br />
            </div>
        );
    }
}

export default Terms;
