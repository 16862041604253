import React, { Component } from "react";
import { Link } from "react-router-dom";

export class BodyArt extends Component {
    render() {
        return (
            <div className="home-page">
                <h1>EXPLORE</h1>
                <div className="page">
                    <div className="page-button-section">
                        <div className="page-button-section2">
                            <Link to="/classic-art">
                                <h1>CLASSIC</h1>
                            </Link>
                        </div>

                        <div className="page-button-section1">
                            <h1>URBAN</h1>
                        </div>
                    </div>
                    <h3>COSMETICS</h3>
                    <h3>TATTOOS</h3>

                    <Link to="/">
                        <button className="back-button">BACK TO HOME</button>
                    </Link>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        );
    }
}

export default BodyArt;
