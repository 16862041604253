import React from 'react'

export default function Dashboard() {
    return (
        <div className="page">
            <br />
            <br />
            <br />
            <br />
            <h1>ADMIN</h1>
        </div>
    )
}
