import React from 'react'
import { Link } from "react-router-dom"

export default function Store() {
    return (
        <div className="page">
            <br />
            <br />
            <br />
            <br />
            <h1>COLLECTIONS</h1>
            <div className="page-button-section">
                <div className="page-button-section1">
                    <h1>APPAREL</h1>
                </div>

                <div className="page-button-section2">
                    <Link to="/artworks">
                        <h1>ARTWORKS</h1>
                    </Link>
                </div>
            </div>
            <div className="tshirt">
                <p>T-shirt</p>
                <select>
                    <option>Size</option>
                    <option>S</option>
                    <option>M</option>
                    <option>L</option>
                    <option>XL</option>
                    <option>XXL</option>
                    <option>XXXL</option>
                </select>
                <button>Add to cart</button>
            </div>
            <Link to="/">
                <button className="back-button">BACK TO HOME</button>
            </Link>

        </div>
    )
}
